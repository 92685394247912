import { JSX } from 'react';
import Placeholder from '@/storychief/components/Placeholder';
import SidebarHeader from '@/storychief/components/SidebarHeader';
import SidebarContent from '@/storychief/components/SidebarContent';

type SidebarPlaceholderType = {
  sidebarId: string;
  onClose: () => void;
};

function SidebarPlaceholder({ sidebarId, onClose }: SidebarPlaceholderType): JSX.Element {
  return (
    <>
      <SidebarHeader
        sidebarId={sidebarId}
        title={<Placeholder width={150} size="lg" variant="dark" />}
        onClose={onClose}
      />
      <SidebarContent>
        <div className="group-y-8">
          <Placeholder width={210} size="lg" space={2} variant="dark" />
          <div className="placeholder-animated-2">
            <div className="ctrl h-5" />
            <div className="ctrl h-5" />
            <div className="ctrl ctrl-80 h-5" />
          </div>

          <Placeholder width={120} size="lg" space={2} variant="dark" />
          <div className="placeholder-animated-2">
            <div className="ctrl h-5" />
            <div className="ctrl h-5" />
            <div className="ctrl ctrl-30 h-5" />
          </div>

          <Placeholder width={120} size="lg" space={2} variant="dark" />
          <div className="placeholder-animated-2">
            <div className="ctrl h-5" />
            <div className="ctrl h-5" />
            <div className="ctrl ctrl-80 h-5" />
          </div>
        </div>
      </SidebarContent>
    </>
  );
}

export default SidebarPlaceholder;
