import { JSX } from 'react';
import Sidebar from '@/storychief/components/Sidebar';
import Button from '@/storychief/components/Button';

type SidebarNotFoundType = {
  sidebarId: string;
  onClose: () => void;
};

function SidebarNotFound({ sidebarId, onClose }: SidebarNotFoundType): JSX.Element {
  return (
    <>
      <Sidebar.Header
        sidebarId={sidebarId}
        onClose={onClose}
        title={<span className="highlight-marker highlight-marker-warning">Not Found</span>}
      />
      <Sidebar.Content>
        <div data-testid="sidebar-not-found">
          <p>Uh oh, we can&apos;t seem to find what you were looking for.</p>
          <p>
            Try going back to the previous page or see our{' '}
            <a href="https://help.storychief.io" target="_blank" rel="noreferrer noopener">
              Help Center
            </a>{' '}
            for more information.
          </p>
        </div>
      </Sidebar.Content>
      <Sidebar.Footer>
        <Button variant="secondary" size="sm" onClick={onClose}>
          Close
        </Button>
      </Sidebar.Footer>
    </>
  );
}

export default SidebarNotFound;
