import type React from 'react';

type SidebarFooterProps = {
  children: React.ReactNode;
};

function SidebarFooter({ children = null }: SidebarFooterProps): React.JSX.Element {
  return <div className="sidebar-footer col-xs-12 bg-catskill-white p-3">{children}</div>;
}

export default SidebarFooter;
